* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

.content-rating-btn,
.genre {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}



.category-form {
  background: #fff;
  color: #181818;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  cursor: pointer;
  width: 200px;
}

.category-form select {
  height: 40px;
  outline: none;
  border: none;
  background: transparent;
}

.search-input {
  width: 300px;
  height: 40px;
  padding: 0 10px;
  background: transparent;
  outline: 0;
  color: #fff;
  font-size: 16px;
  border: 1px solid #444d56;
}

.search-button {
  height: 40px;
  width: 40px;
  border: 1px solid #444d56;
  background: transparent;
  outline: 0;
  color: #444d56;
}

.search-bar-mobile {
  display: none;
}

.search-bar-desktop {
  display: flex;
  align-items: center;
}

.genre-pannel {
  background: #202020;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
}

.genre-pannel button {
  color: #fff;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
}



.iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ifarme-container {
  width: 80vw;
  height: 80vh;
}

#upload-btn {
  text-transform: capitalize;

}

.upload-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: #ffffff;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 1rem;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

@media (max-width: 767px) {
  
  .ifarme-container {
    width: 90vw;
    height: 50vh;
  }

  .search-bar-desktop {
    display: none;
  }

  .search-bar-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .upload-modal {
    width: 300px;
   
   
  }
}





